@import "variables";

.barcodeScanner {
  display: block;
  height: 100%;
  width: 100%;
}

.video {
  height: 100vh;
  left: 0;
  position: absolute;
  top: 0;
  width: 100vw;
}
