@import "variables";
@import "bootstrap/scss/mixins";

.CallToActionSmall {
  background-color: #f6f6f6;
  border-radius: 0.625rem;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 12%);
  margin-bottom: 1.5rem;
  min-height: 350px;
  padding: 4rem 0 4rem 4rem;

  .title {
    color: var(--theme-color-2);
    font-size: 1.25rem;
  }

  .subtitle {
    font-family: Roboto, sans-serif;
    font-size: 1rem;
    margin-top: 0.5rem;
  }

  .image {
    border-radius: 10px;
    height: auto;
    max-height: 200px;
    max-width: 300px;
    padding-left: 1rem;
    width: 100%;
  }

  @include media-breakpoint-down(md) {
    height: 70%;
    margin-bottom: 0;
  }

  @include media-breakpoint-down(sm) {
    height: auto;
    margin-bottom: 2.5rem;
  }
}

