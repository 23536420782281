@import "variables";
@import "utilities/theme-colors";

.product-specialist {
  @extend %bg-theme-2;

  background-clip: border-box;
  border-radius: $border-radius;
  box-shadow: $box-shadow-color;
  color: $white;
  display: flex;
  flex-direction: column;
  position: relative;
  word-wrap: break-word;

  &::before {
    color: rgba(255, 255, 255, 12%);
    content: "\e80d";
    display: block;
    /* stylelint-disable-next-line font-family-no-missing-generic-family-keyword */
    font-family: carellurvink-icon;
    font-size: 126px;
    height: 100%;
    left: 0;
    margin-left: -60px;
    margin-top: -83px;
    position: absolute;
    top: 50%;
    width: 60px;
  }

  .heading {
    display: flex;
    font-family: Roboto, sans-serif;
    font-weight: 500;
    justify-content: center;
    margin-top: -0.75rem;
  }

  .headingText {
    background-color: $white !important;
    color: var(--theme-color-2);
    display: inline-block;
    font-size: 1.125rem;
  }

  .text {
    color: $white;
    font-family: Roboto, sans-serif;
    font-size: 0.875rem;
    font-size: 1rem;
    font-weight: 500;
    line-height: 1.5;
  }

  .link {
    color: $white;
    font-family: $font-family-sans-serif;
    font-size: 0.875rem;
    font-weight: 500;
  }

  .link:hover {
    color: $gray-100;
  }

  .name {
    font-size: 1.125rem;
  }

  .image {
    margin-left: -1rem;
    max-height: 125px;
    width: unset;
  }
}
