@import "variables";

.PaginationContainer {
  display: grid;
  place-items: center;
  width: 100%;
}

.Pagination {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 0.5rem;

  li {
    display: grid;
    place-items: center;
  }
}

.PageLink {
  font-size: 1rem;
  margin-right: 1rem;
}

.PageLinkSelected {
  color: var(--theme-color-2);
}

.PageLinkFirst,
.PageLinkLast,
.PageLinkNext,
.PageLinkPrev {
  border-color: $orange;
  color: $primary;
}

.PageLinkDisabled {
  border-color: transparent;
  cursor: default;
  pointer-events: none;
}
