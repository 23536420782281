@import "variables";
@import "bootstrap/scss/mixins";

.ProductSpecialistWide {
  border-radius: 10px !important;

  .image {
    border-radius: 10px;
    height: auto;
    max-width: 400px;
    position: relative;
    width: 100%;
    z-index: 1;
  }

  .shield {
    font-size: 133px;
    left: 70%;
    opacity: 5%;
    position: absolute;
    transform: translate(-30%, 0);
    z-index: 0;
  }

  .content {
    padding-top: 2rem;
  }

  @include media-breakpoint-up(md) {
    & {
      margin-bottom: 35px;
      margin-top: 95px;
    }

    .image {
      margin-bottom: -35px;
      margin-top: -95px;
      transform: rotate(-2deg);
    }

    .content {
      padding-top: 0;
    }

    .shield {
      left: 50%;
      margin-left: -66.66px;
      transform: translate(-50%, 0);
    }
  }

  .title {
    color: var(--theme-color-1);
    font-family: Roboto, sans-serif;
    font-size: 1.25rem;
  }

  .subText {
    color: rgba(0, 0, 0, 54%);
    font-family: Roboto, sans-serif;
    font-size: 1rem;
    font-weight: normal;
    padding-bottom: 1rem;
  }

  .iconLink {
    padding-bottom: 1rem;

    .icon {
      color: var(--theme-color-2);
      height: 1.125rem;
      width: 1.125rem;
    }

    .link {
      font-family: Roboto, sans-serif;
      font-size: 0.875rem;
      line-height: 1rem;
    }
  }
}
