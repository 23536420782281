$themeColor: "var(--theme-color)";

@import "swiper/scss";
@import "swiper/scss/lazy";
@import "swiper/scss/navigation";
@import "swiper/scss/pagination";

.swiper-button-prev::after,
.swiper-button-next::after {
  font-size: inherit !important;
}
