@import "variables";


.SearchBoxLabel {
  margin-bottom: 0;
  position: relative;
  width: 100%;
}

.SearchBoxInput {
  background-color: #F9F9F9;
  border: 1px solid rgba(0, 0, 0, 12%);
  border-radius: 0 !important;
  font-size: 1rem;
  height: 50px;
  padding: 0.75rem;
  position: relative;
  width: 100%;
  z-index: 10;

  &::placeholder {
    color: rgba(0, 0, 0, 54%);
  }

  /* clears the ‘X’ from Internet Explorer */
  &[type="search"]::-ms-clear {
    display: none;
    height: 0;
    width: 0;
  }

  &[type="search"]::-ms-reveal {
    display: none;
    height: 0;
    width: 0;
  }

  /* clears the ‘X’ from Chrome */
  &[type="search"]::-webkit-search-decoration,
  &[type="search"]::-webkit-search-cancel-button,
  &[type="search"]::-webkit-search-results-button,
  &[type="search"]::-webkit-search-results-decoration {
    display: none;
  }
}

.SearchOverlay::before {
  background-color: rgba(0, 0, 0, 50%);
  content: "";
  left: 0;
  opacity: 0%;
  overflow: hidden;
  position: fixed;
  top: 0;
  transition: opacity 0.15s ease-in-out;
  width: 0;
  will-change: opacity;
}

.SearchOverlayOpen::before,
.SearchOverlay:focus-within::before {
  height: 100vh;
  opacity: 100%;
  width: 100vw;
  z-index: 1;
}

.SearchButton {
  background-color: transparent;
  border-color: transparent;
  color: var(--theme-color);
  cursor: pointer;
  display: grid;
  font-size: 1.2rem;
  height: 100%;
  place-items: center;
  position: absolute;
  right: 1rem;
  top: 50%;
  transform: translate(0, -50%);
  width: 50px;
  z-index: 10;
}


.SearchIcon {
  svg {
    color: var(--theme-color-2);
    height: 20px;
  }
}


.BarcodeButton {
  align-items: center;
  background-color: var(--theme-color-2);
  border: 0;
  border-radius: 2px;
  box-shadow: 0 0.125rem 0.25rem rgba(5, 4, 4, 7.5%);
  color: #fff;
  display: flex;
  font-size: 1.5rem;
  height: 50px;
  justify-content: center;
  line-height: 50px;
  margin-right: -15px; // offset BS grid setting
  padding: 0 1rem;
  z-index: 10;

  .label {
    font-size: 1rem;
  }

  svg {
    width: 25px;
  }

  &:active,
  &:focus,
  &:hover {
    background-color: var(--theme-color-1);
  }
}
