@import "variables";

.VariantOptions :global {
  .swiper-button-prev,
  .swiper-button-next {
    background-color: $gray-100;
    border: none;
    color: $gray-500;
    font-size: 1.5rem;
    text-align: center;
    width: 32px;

    &:disabled {
      color: $gray-200;
      pointer-events: none;
    }
  }

  .swiper-button-prev {
    left: 0;
  }

  .swiper-button-next {
    right: 0;
  }

  .swiper-slide {
    width: auto;
  }
}

.SwiperWrapper {
  width: 100%;
}


.VariantOptions {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin-bottom: 0.5rem;
  position: relative;
  width: 100%;
}

.VariantOptions:last-child {
  margin-bottom: 0;
}


.ColorOption {
  border: 3px solid $gray-300;
  border-radius: 100%;
  cursor: pointer;
  display: grid;
  place-items: center;
}

.ColorOptionSelected {
  border-color: $orange;
}

.ColorOptionDisabled {
  border-color: $gray-200;
  filter: grayscale(50%) opacity(50%);
  pointer-events: none;
}

.ColorOptionImage {
  border: 2px solid $white;
  border-radius: 100%;
  height: 30px;
  width: 30px;
}


.LabelOption {
  align-items: center;
  background-color: $white;
  border: 2px solid $gray-300;
  border-radius: 2px;
  cursor: pointer;
  display: flex;
  font-size: 0.875rem;
  font-weight: 500;
  height: 38px;
  justify-content: center;
  line-height: 38px;
  min-width: 38px;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  text-align: center;
  text-transform: uppercase;
  width: auto;
}

.LabelOptionSelected {
  border-color: $orange;
}

.LabelOptionDisabled {
  border-color: $gray-200;
  filter: grayscale(50%) opacity(50%);
  pointer-events: none;
}
