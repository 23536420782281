.ReactComponentFullError {
  background-color: #efefef;
  padding: 10px;

  h3 {
    color: red;
    margin-bottom: 1rem;
  }
}

.ReactComponentError {
  span {
    color: red;
  }
}
