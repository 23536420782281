@import "variables";

.Labels {
  display: flex;
  flex-direction: column;
  position: absolute;
  right: 0;
  z-index: 10;
}

.Label {
  border-radius: 2px 0 0 2px;
  color: $white;
  font-size: 0.75rem;
  font-weight: 700;
  padding: 0.425rem 0.625rem;
}

._1PLUS1,
._1plus1 {
  background-color: #ea5d2d;
}

._25KOR,
._25kor {
  background-color: #ea5d2d;
}

._2PLUS1,
._2plus1 {
  background-color: #ea5d2d;
}

._2PLUS2,
._2plus2 {
  background-color: #ea5d2d;
}

._3PLUS1,
._3plus1 {
  background-color: #ea5d2d;
}

._50KOR,
._50kor {
  background-color: #ea5d2d;
}

._AANB,
._aanb {
  background-color: #c71552;
}

._BEKEU,
._bekeu {
  background-color: #ea5d2d;
}

._BEKO,
._beko {
  background-color: #ea5d2d;
}

._CE3,
._ce3 {
  background-color: #1798ae;
}

._CLK,
._clk {
  background-color: #3562a2;
}

._DUURZAAM,
._duurzaam {
  background-color: #c0cd2e;
}

._GRAVER,
._graver {
  background-color: #1798ae;
}

._INDBES,
._indbes {
  background-color: #ce1309;
}

._MEEVERK,
._meeverk {
  background-color: #3562a2;
}

._MVO,
._mvo {
  background-color: #7e9c2a;
}

._NIEUW,
._nieuw {
  background-color: #1798ae;
}

._SALE,
._sale {
  background-color: #e6332a;
}

._BLACKF,
._blackf {
  background-color: #000;
  color: #dc3545;
}

._CAT1,
._cat1 {
  background-color: #17a2b8;
  color: rgba(255, 255, 255, 87%);
}

._CAT2,
._cat2 {
  background-color: #17a2b8;
  color: rgba(255, 255, 255, 87%);
}

._CAT3,
._cat3 {
  background-color: #17a2b8;
  color: rgba(255, 255, 255, 87%);
}

._DIRECTLEV,
._directlev {
  background-color: #28a745;
}

._ECOLO,
._ecolo {
  background-color: #28a745;
}

._BIOLO,
._biolo {
  background-color: #20c997;
}

._PROBIO,
._probio {
  background-color: #28a745;
}

._INPRIJS,
._inprijs {
  background-color: #dc3545;
}

._CHEMIEVRIJ,
._chemievrij {
  background-color: #8cc640;
}
