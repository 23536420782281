@import "variables";
@import "bootstrap/scss/mixins";


.fullPage {
  background-color: $gray-100;
  display: flex;
  flex-direction: column;
  height: 100vh;
  height: 100dvh; /* https://caniuse.com/viewport-unit-variants */
  left: 0;
  overflow: hidden;
  position: fixed;
  top: 0;
  transform: translateY(100%);
  transition: transform 0.3s ease-in-out;
  width: 100vw;
  z-index: 200;
}

.fullPage.isOpen {
  transform: translateY(0);
}

.fullPage.isOpen > * > .fullPage {
  transform: translateY(0);
}


.fullPageScrollPane {
  height: 100%;
  overflow-x: hidden;
  width: 100%;
}

.fullPageScrollPaneContent {
  height: 100%;
  overflow-y: auto;
  position: relative;
  width: 100%;
}


.fullPageHeader {
  align-items: center;
  background-color: $white;
  border-bottom: 1px solid $gray-200;
  display: flex;
  height: 50px;
  justify-content: space-between;
  position: relative;
  width: 100%;
}


.fullPageFooter {
  background-color: $white;
  bottom: 68px; // Offset .fullpage top: 68px;
  box-shadow: rgba(0, 0, 0, 20%) 0 0 8px 0;
  height: 80px;
  position: fixed;
  width: 100%;
  z-index: 10;
}
