/* stylelint-disable no-duplicate-selectors */
@import "variables";
@import "bootstrap/scss/mixins";
@import "libs/fontawesome/font-awesome-mix-var";


.CategoryFacets {
  width: 100%;

  @include media-breakpoint-down(md) {
    background-color: $white;
    display: flex;
    flex-direction: column;
    height: 100%;
    left: 0;
    overflow: hidden;
    position: fixed;
    top: 0;
    transform: translateY(120%);
    transition: transform 0.3s ease-in-out;
    width: 100%;
    z-index: 1000;

    &:global(.isOpen) {
      transform: translateY(0);
    }
  }
}

.CategoryFacetsFilter {
  border-top: 2px solid $gray-200;

  @include media-breakpoint-up(lg) {
    max-width: 250px;
  }
}

.CategoryFacetsFilterToggle {
  border: 0;
  display: flex;
  font-family: $font-family-sans-serif;
  font-weight: 500;
  height: 42px;
  justify-content: space-between;
  line-height: 42px;
  padding-left: 0;
  text-align: left;

  &::after,
  &[aria-expanded="true"]::after {
    @include fa-icon;

    font-size: 22px;
    line-height: 42px;
  }

  &::after {
    content: $fa-var-angle-down;
  }

  &[aria-expanded="true"]::after {
    content: $fa-var-angle-up;
  }

  &:hover {
    text-decoration: none;
  }

  .FacetToggleValue {
    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: calc(100% - 1.25rem);
  }
}

.FacetLabel {
  display: flex;
  justify-content: space-between;
  margin-top: 0.5rem;
}

.FacetLabelValue {
  display: inline-block;
  flex-grow: 1;
  flex-shrink: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 200px;
}

.FacetsModalHeader {
  background: #fff;
  display: flex;
  justify-content: space-between;
  width: 100%;
  z-index: 1;

  @include media-breakpoint-up(lg) {
    position: fixed;
    top: 0;
  }

  :global(.h1) {
    font-size: 1rem;
  }
}

.FacetsModalContent {
  @include media-breakpoint-down(md) {
    max-height: calc(100% - 138px);
    overflow: auto;
  }
}


.FacetsModalFooter {
  background: #fff;
  bottom: 0;
  height: 82px;
  position: fixed;
  width: 100%;
}
