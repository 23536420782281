@import "variables";

:global {
  :local(.SwipeableProductTileList) > .swiper {
    margin-left: revert;
    margin-right: revert;
    padding-bottom: 1rem;
  }

  :local(.SwipeableProductTileList) .swiper-button-prev::after,
  :local(.SwipeableProductTileList) .swiper-button-next::after {
    font-size: 1.5rem !important;
  }


  :local(.SwipeableProductTileList) > .swiper > .swiper-wrapper > .swiper-slide {
    width: 235px;
  }
}

.SwipeableProductTileList {
  flex-wrap: nowrap;
  // height: 540px;
}

.buttons {
  position: absolute;
  top: 240px;
  width: 100%;
}

.btnListSlide {
  background-color: $white;
  border: 2px solid $orange;
  border-radius: 2px;
  color: $orange;
  font-size: 1.75rem;
  height: 40px;
  line-height: 0.8;
  width: 40px;

  &[disabled],
  :global(.disabled) {
    border-color: $gray-200;
    color: $gray-200;
    opacity: 90%;
  }
}
