@import "variables";
@import "bootstrap/scss/mixins";


.ModalWrapper {
  position: absolute;
  top: 200px;
  width: 100%;
  z-index: 110;

  @include media-breakpoint-up(lg) {
    display: grid;
    height: 100vh;
    left: 0;
    place-items: start center;
    top: 130px;
  }
}



.SearchModal {
  background-color: #FFF;
  width: 100%;

  @include media-breakpoint-up(lg) {
    background-color: #FFF;
    border-radius: 0.625rem;
    margin-left: -0.5rem;
    margin-right: -0.5rem;
    max-width: 1240px;
    min-width: 500px;
    width: 100%;
  }

  .Facets {
    width: 0;

    @include media-breakpoint-up(lg) {
      background-color: $gray-50;
      border: 1px solid $gray-200;
      border-radius: 0.625rem 0 0 0.625rem;
      display: flex;
      padding-top: 1rem;
      width: 250px;
    }
  }

  .FacetsNoResults {
    background-color: $white !important;
    border-right-color: transparent !important;
  }

  .Results {
    border: 1px solid $gray-200;
    border-radius: 0.625rem;
    box-shadow: 20px 12px 34px 0 rgba(0, 0, 0, 10%);


    @include media-breakpoint-up(lg) {
      border-left-width: 0;
      border-radius: 0 0.625rem 0.625rem 0;
    }
  }

  [class*="CategoryFacets-module"] {
    &:first-child {
      border-top: none;
    }
  }
}


