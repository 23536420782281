@import "variables";
@import "bootstrap/scss/mixins";

.FilterBadgeList {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;

  @include media-breakpoint-up(md) {
    align-items: center;
    flex-direction: row;
  }
}

.FilterBadge {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 0.5rem;
}

.code {
  font-weight: 600;
}

.value {
  font-weight: 400;
}
