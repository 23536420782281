@import "variables";
@import "bootstrap/scss/mixins";

$transtionDelay: 150ms;

.ProductTile {
  background-color: $white;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  position: relative;
  transition-delay: $transtionDelay;
  width: 100%;
  z-index: 1;

  @include media-breakpoint-up(sm) {
    height: 511px;
    width: 50%;
  }

  @include media-breakpoint-up(md) {
    width: 310px;
  }

  :global(.swiper-slide) & {
    height: auto !important;
    width: 100% !important;
  }

  .VariantOptionsContainer {
    background-color: $gray-100;
    border-bottom: 0 solid $gray-200;
    border-top: 0 solid $gray-200;
    height: auto;
    max-height: 0;
    overflow: hidden;
    transition: 250ms;
    transition-delay: $transtionDelay;
    transition-timing-function: ease-in-out;
    width: 100%;
    z-index: 20;
  }
}

.ImageComponent {
  aspect-ratio: 1/1;
  width: 100%;

  img {
    object-fit: cover;
  }
}

.ProductTile:focus,
.ProductTile:focus-within,
.ProductTile:hover {
  display: flex;
  flex-direction: column;
  position: relative;
  transition-delay: $transtionDelay;
  z-index: 20;

  .componentContent {
    background-color: $white;
    box-shadow: 3px 5px 9px 0 rgba(0, 0, 0, 3%), 10px 10px 10px 0 rgba(0, 0, 0, 6%);
  }

  .VariantOptionsContainer {
    border-bottom-width: 1px;
    border-top-width: 1px;
    margin-bottom: 1rem;
    max-height: 150px;
    padding-bottom: 0.5rem;
    padding-top: 0.5rem;
    transition-delay: 0ms;
  }
}

/**
 * Make sure ProductTile has highest Z-index when there is focus on select or input
 */
.ProductTile:focus-within {
  z-index: 30;
}

.Meta {
  overflow: hidden;
  position: relative;

  .h3 {
    height: 2.8rem;
    overflow: hidden;
  }
}

.pricing {
  margin-top: auto;
}

.Prices {
  margin-top: auto;

  > a {
    display: inline-block;
    overflow: hidden;
    width: 100%;
  }
}

.PriceLabel {
  display: inline-block;
  font-family: $font-family-sans-serif;
  font-size: 0.75rem;
  font-weight: 600;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
}

.AddToCart {
  background-color: $white;
  bottom: 0;
  margin-top: auto;
}

.AddToCartAmount {
  background-color: $gray-100;
  border: 0;
  height: 50px;
}

.AddToCartAmountUOM {
  background-color: $white;
  border-color: $gray-200;
  height: 50px;
}
