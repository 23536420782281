@import "variables";
@import "bootstrap/scss/mixins";

.CustomerRatingsCarousel {
  width: 320px;
  @include media-breakpoint-up(sm) { width: map-get($grid-breakpoints, "sm"); }
  @include media-breakpoint-up(md) { width: map-get($grid-breakpoints, "md"); }
  @include media-breakpoint-up(lg) { width: map-get($grid-breakpoints, "lg"); }
  @include media-breakpoint-up(xl) { width: map-get($grid-breakpoints, "xl"); }
}

.CustomerRatingCarouselItem {
  display: grid;
  flex: 0 0 auto;
  font-family: $font-family-sans-serif;
  font-size: 0.75rem;
  font-weight: 500;
  padding: 0.5rem;
  place-items: center;
  width: 100%;

  .review {
    display: flex;

    @include media-breakpoint-down(sm) {
      align-items: center;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }

  .reviewAuthor {
    font-size: 1rem;
    margin-right: 1rem;
  }

  .ratingContainer {
    color: $yellow;
    display: inline-block;
    font-size: 1rem;
    height: 1rem;
    position: relative;
    text-align: left;
    width: calc(18px * 5);

    .ratingBackground {
      height: inherit;
      left: 0;
      overflow: hidden;
      position: absolute;
      top: 0;
      white-space: nowrap;
      width: 100%;
    }

    .ratingOverlay {
      height: inherit;
      left: 0;
      overflow: hidden;
      position: absolute;
      top: 0;
      white-space: nowrap;
      z-index: 10;
    }
  }
}

