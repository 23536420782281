@import "variables";
@import "bootstrap/scss/mixins";

:global {
  .product-listing-wrapper {
    position: relative;

    .component.product-listing {
      box-sizing: border-box;
      margin-bottom: 0.875rem;

      @include media-breakpoint-up(md) {
        height: 340px;
      }

      @include media-breakpoint-up(lg) {
        height: 340px;
      }

      @include media-breakpoint-up(xl) {
        height: 400px;
      }
    }

    .component-content {
      width: 100%;
    }
  }
}

.ProductTileList {
  background-color: $white;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  min-height: 100px;
  position: relative;
}
