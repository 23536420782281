@import "libs/fontawesome/font-awesome-mix-var";
@import "variables";

.CallToActionLargeVariants {
  padding-bottom: 6rem;
  padding-top: 6rem;

  .image {
    border-radius: 10px;
    height: auto;
    max-width: 500px;
    width: 100%;
  }

  .header {
    margin-bottom: 2rem;
    text-align: center;
  }

  .title {
    color: var(--text-white);
    font-size: 2.375rem;
  }

  .subtitle {
    color: var(--text-gray-lighten);
  }

  .button {
    background-color: $white;
    border: 0;
    border-radius: 10px;
    display: inline-block;
    margin-bottom: 1rem;
    margin-top: 1rem;
    padding-bottom: 1.375rem;
    padding-left: 2rem;
    padding-top: 1.375rem;
    width: 100%;
  }

  .button:hover .buttonTitle::after {
    transform: translateX(0.5rem);
  }

  .buttonImage {
    max-width: 60px;
    width: 100%;
  }

  .buttonTitle {
    font-family: Roboto, sans-serif;
    font-size: 1rem;
    font-weight: bold;
    line-height: 1.375rem;
    padding-top: 0.25rem;
  }

  .buttonText {
    color: rgba(0, 0, 0, 54%);
    font-family: Roboto, sans-serif;
    line-height: 1.25rem;
  }

  .button:hover {
    background-color: rgba(255, 255, 255, 90%);
    box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 12%);
  }

  .buttonTitle::after {
    @include fa-icon;

    color: var(--theme-color-2);
    content: $fa-var-long-arrow-right;
    margin-left: 0.5rem;
    transition: transform 0.15s ease-in-out;
  }
}
