/* stylelint-disable no-duplicate-selectors, no-descending-specificity */
@import "variables";
@import "utilities/typography";

.component.product-add-to-cart,
.component.product-addtobasket {
  .add-to-cart {
    background-color: $white;
    bottom: 0;

    .btn-cart {
      height: 50px;
      width: 100%;

      &[disabled] {
        background-color: gray;
        border-color: lightgray;
        cursor: not-allowed;
      }

      .btn-label,
      .fa {
        color: $white;
        font-weight: 600;
      }
    }

    .amount {
      background-color: $gray-100;
      border-color: $gray-200;
      color: $gray-500;
      font-size: 1rem;
      height: 50px;
      max-width: 110px;

      &:focus {
        background-color: $white;
      }
    }

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      appearance: none;
      margin: 0;
    }

    input[type="number"] {
      appearance: textfield;
    }
  }
}
