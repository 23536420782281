@use "sass:list";
@import "vars";
@import "functions";

/* breakpoints */

// TODO: Deperectate these vars
$break-desktop: 960px;
$break-mobile: 380px;
$break-mobile-horizontal: 640px;
$break-mobile-large: 786px;

@mixin wrapper() {
  margin: 0 auto !important;
  max-width: 960px;
}

@mixin clearfix {
  &::after {
    clear: both;
    content: "";
    display: table;
  }
}

@mixin headings($from: 1, $to: 6) {
  @for $i from $from through $to {
    h#{$i} {
      @content;
    }
  }
}

@mixin font-size($sizeValue: 1.6) {
  font-size: ($sizeValue * 10) + px;
  font-size: $sizeValue + rem;
}

@mixin proxima-font($weight: semibold) {
  @if $weight == semibold {
    font-family: ProximaNova-Semibold, arial, helvetica, sans-serif;
  } @else if $weight == light {
    font-family: ProximaNova-Light, arial, helvetica, sans-serif;
  }
}

@mixin opensans-font-stack() {
  font-family: Roboto, sans-serif, "Open Sans", Helvetica, Verdana, Tahoma;
}

@mixin loading-gif() {
  background-image: url("data:image/svg+xml;charset=utf-8;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAzMiAzMiIgd2lkdGg9IjMyIiBoZWlnaHQ9IjMyIiBmaWxsPSJibGFjayI+DQogIDxwYXRoICBvcGFjaXR5PSIuMjUiIGQ9Ik0xNiAwIEExNiAxNiAwIDAgMCAxNiAzMiBBMTYgMTYgMCAwIDAgMTYgMCBNMTYgNCBBMTIgMTIgMCAwIDEgMTYgMjggQTEyIDEyIDAgMCAxIDE2IDQiLz4NCiAgPHBhdGggZmlsbD0nIzFhODBiNicgZD0iTTE2IDAgQTE2IDE2IDAgMCAxIDMyIDE2IEwyOCAxNiBBMTIgMTIgMCAwIDAgMTYgNHoiPg0KICAgIDxhbmltYXRlVHJhbnNmb3JtIGF0dHJpYnV0ZU5hbWU9InRyYW5zZm9ybSIgdHlwZT0icm90YXRlIiBmcm9tPSIwIDE2IDE2IiB0bz0iMzYwIDE2IDE2IiBkdXI9IjAuOHMiIHJlcGVhdENvdW50PSJpbmRlZmluaXRlIiAvPg0KICA8L3BhdGg+DQo8L3N2Zz4NCg==");
  background-position: center center;
  background-repeat: no-repeat;
}

// TODO: deprecate this mixin and replace with @media-breapoint-up
@mixin respond-to($media) {
  @if $media == mobile {
    @media only screen and (max-width: $break-mobile) {
      @content;
    }
  } @else if $media == mobile-horizontal {
    @media only screen and (max-width: $break-mobile-horizontal - 1) {
      @content;
    }
  } @else if $media == mobile-large {
    @media only screen and (max-width: $break-mobile-large) {
      @content;
    }
  } @else if $media == tablet {
    @media only screen and (min-width: $break-mobile + 1) and (max-width: $break-desktop - 1) {
      @content;
    }
  } @else if $media == all-mobile {
    @media only screen and (max-width: $break-desktop - 1) {
      @content;
    }
  } @else if $media == desktop {
    @media only screen and (min-width: $break-desktop) {
      @content;
    }
  }
}

@mixin media-breakpoint-up($name, $breakpoints: $grid-breakpoints) {
  $min: breakpoint-min($name, $breakpoints);

  @if $min {
    @media (min-width: $min) {
      @content;
    }
  } @else {
    @content;
  }
}

@mixin border-basic($position: all, $border-color: $border-gray, $border-width: 1px) {
  @if $position != all {
    border-#{$position}-width: $border-width;
    border-#{$position}-style: solid;
    border-#{$position}-color: $border-color;
  } @else {
    border-color: $border-color;
    border-style: solid;
    border-width: $border-width;
  }
}

@mixin fixed-bg($pos, $min-height: 240px) {
  background-attachment: fixed;
  background-position: $pos;
  min-height: $min-height;
}

@mixin linear-gradient($direction, $color-stops...) {
  // Direction has been omitted and happens to be a color-stop
  @if is-direction($direction) == false {
    $color-stops: $direction, $color-stops;
    $direction: 180deg;
  }

  background: list.nth(list.nth($color-stops, 1), 1);
  background: linear-gradient(legacy-direction($direction), $color-stops);
  background: linear-gradient($direction, $color-stops);
}
