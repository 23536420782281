@import "variables";
@import "bootstrap/scss/mixins";

.uspContainer {
  height: 0;
  position: relative;
  width: 100%;
}

.uspList {
  align-items: center;
  background-color: #FCFFF8;
  border-radius: 20px;
  box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 12%);
  display: flex;
  flex-direction: row;
  height: 3rem;
  height: 90px;
  justify-content: space-between;
  left: 50%;
  max-width: 1240px;
  position: absolute;
  top: 0;
  transform: translate(-50%, -50%);
  width: 100%;

  @include media-breakpoint-down(md) {
    justify-content: center;
  }
}


.uspItem {
  color: var(--usp-color);
  font-family: Roboto, sans-serif;
  font-size: 1rem;
  font-weight: 700;

  &:first-child {
    padding-left: 3rem;
  }

  &:last-child {
    padding-right: 3rem;
  }
}


.checkCircle {
  align-items: center;
  display: flex;
  font-size: 1rem;
  height: 32px;

  &::before {
    background-color: var(--usp-color);
    background-position: center;
    content: " ";
    display: inline-block;
    height: 32px;
    margin-right: 0.5rem;
    /* stylelint-disable-next-line property-no-vendor-prefix */
    -webkit-mask-image: url("../../../static-assets/images/checkmark-circle.svg");
    mask-image: url("../../../static-assets/images/checkmark-circle.svg");
    width: 32px;
  }
}
