@import "variables";

.modalHeader {
  padding: 0.75rem 1.5rem 1.5rem;
  width: 600px;
}

.border-bottom {
  border-bottom: 1px solid $gray-200;
}

.formHeader {
  align-items: center;
  border-bottom: 1px solid $gray-200;
  display: flex;
  justify-content: space-between;
  margin-top: -0.25rem;
  padding-bottom: 0.25rem;
}

.selectButton {
  text-decoration: underline;
}

.thumbnail {
  height: 100%;
  max-height: 80px;
  max-width: 140px;
  width: auto;
}


.list {
  height: 175px;
  overflow-x: hidden;
  overflow-y: auto;
}

.listItem {
  border-bottom: 1px solid $gray-200;
  padding: 1rem 0;

  &:last-child {
    border-bottom: none;
  }
}


.priceLabel {
  font-family: $font-family-sans-serif;
  font-size: 0.75rem;
  font-weight: 600;
  margin-left: 0.25rem;
}
