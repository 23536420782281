@import "variables";

.disabled {
  cursor: default;
  filter: opacity(0.5);
  pointer-events: none;
}

.shoppingCart {
  align-content: center;
  display: inline-grid;

  svg {
    height: 0.875rem;
  }

  .btnShoppingcart {
    border-width: 0;
    color: $white;
    padding: 0;
    position: relative;
  }


  .btnShoppingcart:active,
  .btnShoppingcart:focus,
  .btnShoppingcart:hover {
    background-color: var(--theme-color-1);
  }

  .badge {
    background-color: var(--tab-active-color);
    transform: translateY(-8px);
  }

  .badge[data-basket-count="0"] {
    display: none;
  }
}


.btn-sm {
  svg {
    height: 0.75rem;
  }

  .btnShoppingcart {
    height: 2rem;
    line-height: 2rem;
    width: 2rem;
  }
}

.btn-md {
  svg {
    height: 1rem;
  }

  .btnShoppingcart {
    height: 2.5rem;
    line-height: 2.5rem;
    width: 2.5rem;
  }
}


.btn-lg {
  svg {
    height: 21px;
  }

  .btnShoppingcart {
    height: 50px;
    line-height: 50px;
    width: 50px;
  }
}


.btn-primary {
  .btnShoppingcart:hover {
    background-color: #d97700;
    border-color: #cc7000;
    color: #fff;
  }

  .btnShoppingcart:active,
  .btnShoppingcart:focus, {
    background-color: #cc7000;
    border-color: #bf6900;
    box-shadow: inset 0 3px 5px rgba(5, 4, 4, 12.5%);
    color: #fff;
  }
}

.btn-outline-primary {
  .btnShoppingcart {
    border: 1px solid #ff8c00;
    color: #ff8c00;
  }

  .btnShoppingcart:active {
    background-color: #ff8c00;
    border-color: #ff8c00;
    box-shadow: inset 0 3px 5px rgba(5, 4, 4, 12.5%);
    color: rgba(0, 0, 0, 87%);
  }

  .btnShoppingcart:focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 140, 0, 25%);
    outline: 0;
  }

  .btnShoppingcart:hover {
    background-color: #ff8c00;
    border-color: #ff8c00;
  }

  .basketCount::before {
    background-color: var(--tab-active-color);
    text-shadow: 1px 1px $gray-800;
  }

  .disabled {
    background-color: rgba(var(--theme-color-2), 0.5);
  }
}


.btn-theme {
  .btnShoppingcart {
    background-color: var(--theme-color-2);
  }

  .btnShoppingcart:active,
  .btnShoppingcart:focus,
  .btnShoppingcart:hover {
    background-color: var(--theme-color-1);
  }

  .disabled {
    background-color: rgba(var(--theme-color-2), 0.5);
  }
}
