/**
 * Create @supports() At-rules in selector
 */
@mixin supports($property) {
  $property: "(" + $property + ")";

  @supports #{$property} {
    @content;
  }
}


@mixin google-font($family) {
  @import url("http://fonts.googleapis.com/css?family=#{$family}");
}
