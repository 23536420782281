@import "variables";
@import "abstracts/mixins";

.pricelabel {
  align-items: flex-start;
  display: flex;
  margin-bottom: 0.25rem;
}

.pricelabelPrice {
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 0.9;
  margin-right: 0.5rem;
}

.pricelabelUnitOfMeasure {
  font-size: 1rem;
  font-weight: 500;
  line-height: 1;
}

.variantOptions {
  display: grid;
  gap: 0.75rem;
  grid-template-columns: repeat(auto-fit, 38px);
}

.variantOptionsSize {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.variantCollapseContainer {
  margin-right: -1rem;
  max-height: 7.5rem;
  overflow: hidden;
  transition: max-height 250ms ease-in-out;

  &:global(.show) {
    max-height: 500px;


    ~ .variantCollapseButtonContainer {
      :global(.showMore) {
        display: none;
      }

      :global(.showLess) {
        display: initial;
      }
    }
  }

  ~ .variantCollapseButtonContainer {
    display: none;

    :global(.showLess) {
      display: none;
    }
  }

  &:global(.collapsable) {
    ~ .variantCollapseButtonContainer {
      display: initial;
    }
  }
}

.variantCollapseButtonContainer {
  position: relative;

  &::before {
    background-color: $gray-300;
    content: "";
    display: block;
    height: 1px;
    position: absolute;
    top: 50%;
    width: 100%;
    z-index: -1;
  }

  :global(.btn.btn-outline-primary) {
    box-shadow: #fff 0 0 0 0.5rem;

    &:global(:not(:hover)) {
      background-color: #fff;
    }
  }
}

.option {
  display: inline-grid;
  place-items: center;
}

.optionColor {
  border: 3px solid $gray-300;
  border-radius: 100%;
  cursor: pointer;
  height: 38px;
  margin-right: 0.75rem;
  width: 38px;

  &:last-child {
    margin-right: 0;
  }
}

.optionColor:hover,
.optionColorActive {
  border-color: $orange;
}

.colorImage {
  border: 2px solid $white;
  border-radius: 100%;
  height: 32px;
  width: 32px;
}

.optionLabel {
  background-color: $white;
  border: 2px solid $gray-300;
  border-radius: 2px;
  cursor: pointer;
  font-size: 0.875rem;
  font-weight: 500;
  height: 46px;
  line-height: 46px;
  margin-bottom: 1rem;
  margin-right: 1rem;
  min-width: 46px;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  text-align: center;
  text-transform: uppercase;

  &:last-child {
    margin-right: 0;
  }
}

.optionLabelDisabled,
.optionLabel[disabled] {
  color: $gray-200;
  cursor: not-allowed;
}



.optionLabel:hover,
.optionLabelActive {
  border-color: $orange;
}

.allUnitsOfMeasure {
  display: flex;
  flex-wrap: wrap;
}

.unitOfMeasure {
  display: block;
  height: auto;
  line-height: 1;
  overflow: hidden;
  padding: 0.75rem;
  text-align: left;
  white-space: nowrap;
  width: fit-content;
}

.unitOfMeasureActive {
  color: $orange;

  @include media-breakpoint-up(md) {
    border-color: $orange;
    color: $gray-900;
  }
}

.unitOfMeasureSingle {
  border-color: transparent !important;
  cursor: default;
  padding-left: 0 !important;
  pointer-events: none;
}
