@import "variables";
@import "utilities/typography";
@import "bootstrap/scss/mixins";

.BrandsList {
  .titleLink {
    font-family: $font-family-sans-serif;
    font-size: 0.875rem;
    font-weight: 500;
  }

  .arrow {
    font-size: 1.25rem;
    padding-left: 0.625rem;
  }

  .brandsList {
    display: grid;
    grid-gap: 0.5rem;
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: auto;

    @include media-breakpoint-up(sm) {
      grid-template-columns: repeat(2, 1fr);
    }


    @include media-breakpoint-up(md) {
      grid-template-columns: repeat(3, 1fr);
    }

    @include media-breakpoint-up(lg) {
      grid-template-columns: repeat(4, 1fr);
    }

    @include media-breakpoint-up(xl) {
      grid-template-columns: repeat(6, 1fr);
    }
  }

  .brand {
    border: 1px solid $gray-200;
    border-radius: 10px;
    display: grid;
    flex-shrink: 0;
    height: 110px;
    margin-bottom: 1rem;
    margin-right: 0.5rem;
    place-items: center;
    width: 100%;

    &:hover {
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 7%);

      .brandImage {
        filter: grayscale(0);
      }
    }

    @include media-breakpoint-up(lg) {
      &:last-child {
        margin-right: 0;
      }
    }
  }

  .brandImage {
    filter: grayscale(100%);
    height: auto;
    max-height: 110px;
    place-self: center;
    transition: filter 150ms ease-in-out;
    width: 100%;
  }

  .brand-link {
    color: $gray-900;
    line-height: 1.5;
  }
}
