.ExampleTypeScript {
  background-color: blue;

  .title {
    font-size: large;
  }

  .text {
    color: green;
  }
}
