@import "variables";
@import "bootstrap/scss/mixins";
$static-assets-path: "../../../../static-assets";

.mediaSwiper :global {
  .swiper-slide {
    text-align: center;
  }

  .swiper-pagination {
    position: absolute;
    text-align: left;
    z-index: 100;
  }

  .swiper-pagination-bullets.swiper-pagination-horizontal {
    bottom: unset;
    margin-top: -1rem;
  }

  .swiper-pagination-bullet {
    background-color: $white;
    border: 1px solid rgb(0, 0, 0);
    border-radius: 100%;
    cursor: pointer;
    display: inline-flex;
    height: 12px;
    margin: 0;
    margin-right: 0.5rem;
    width: 12px;

    &:hover {
      background-color: $gray-200;
    }

    &.swiper-pagination-bullet-active {
      background-color: $gray-300;
      border-color: transparent;
    }
  }
}

.mediaSwiper {

  @include media-breakpoint-down(lg) {
    width: 100%;
  }
}

.image {
  height: auto;
  width: 100%;
}
