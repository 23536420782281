@import "variables";

.HorizontalLine {
  clear: both;

  hr {
    background-color: transparent;
    border: 1px solid transparent;
    border: none;
    height: 1px;
    position: relative;

    &::before {
      background-color: $gray-200;
      content: " ";
      height: 1px;
      margin-left: calc(50% - 50vw);
      overflow: hidden;
      position: absolute;
      top: 0;
      width: 100vw;
    }
  }
}
