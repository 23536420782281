@import "abstracts/mixins";
@import "abstracts/vars";

.CustomerRatings {
  .customerRatings {
    align-content: center;
    display: flex;
    font-family: $font-family-sans-serif;
    font-size: 0.75rem;
    font-weight: 500;
    justify-content: flex-end;

    > * {
      margin-right: 0.25rem;
    }
  }

  .customerRatings a,
  .customerRatings a:hover {
    align-content: center;
    color: $gray-500;
    display: inline-flex;
  }

  .customerRatings span {
    color: $text-basic;
  }
}
