@use "partials/mixins" as *;

%bg-theme {
  background-color: #F0FBFA;

  @include supports ("--css: variables") {
    background-color: var(--theme-accent-color) !important;
  }
}

%text-theme {
  color: #0398CD;

  @include supports ("--css: variables") {
    color: var(--theme-color) !important;
  }
}


%text-theme-1 {
  color: #0385B4;

  @include supports ("--css: variables") {
    color: var(--theme-color-1) !important;
  }
}

%text-theme-2 {
  color: #0398CD;

  @include supports ("--css: variables") {
    color: var(--theme-color-2) !important;
  }
}

%text-theme-3 {
  color: #03ABE6;

  @include supports ("--css: variables") {
    color: var(--theme-color-3) !important;
  }
}

%text-theme-4 {
  color: #232729;

  @include supports ("--css: variables") {
    color: var(--theme-color-4) !important;
  }
}

%bg-theme-1 {
  background-color: #71A330;

  @include supports ("--css: variables") {
    background: var(--theme-color-1) !important;
  }
}

%bg-theme-2 {
  background-color: #8CC640;

  @include supports ("--css: variables") {
    background: var(--theme-color-2) !important;
  }
}

%bg-theme-3 {
  background-color: #A4D267;

  @include supports ("--css: variables") {
    background: var(--theme-color-3) !important;
  }
}

%bg-theme-4 {
  background-color: #F4FFE7;

  @include supports ("--css: variables") {
    background: var(--theme-color-4) !important;
  }
}


%bg-theme-linear-gradient {
  @extend %bg-theme;

  background: linear-gradient(67.32deg, var(--theme-color-1) 0%, var(--theme-color-2) 100%);
}

.bg-theme {
  @extend %bg-theme;
}

.bg-theme-linear-gradient {
  @extend %bg-theme-linear-gradient;
}

.bg-theme-1 {
  @extend %bg-theme-1;
}

.bg-theme-2 {
  @extend %bg-theme-2;
}

.bg-theme-3 {
  @extend %bg-theme-3;
}

.bg-theme-4 {
  @extend %bg-theme-4;
}

.text-theme {
  @extend %text-theme;
}

.text-theme-1 {
  @extend %text-theme-1;
}

.text-theme-2 {
  @extend %text-theme-2;
}

.text-theme-3 {
  @extend %text-theme-3;
}

.text-theme-4 {
  @extend %text-theme-4;
}
