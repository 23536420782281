@import "styles/libs/swiper/swiper";
@import "utilities/typography";
@import "bootstrap/scss/mixins";

.CtaCarousel {
  .swiperPagination {
    position: relative !important;
  }

  :global(.swiper-pagination-bullet) {
    border-color: rgb(0, 0, 0);
    border-radius: 8px !important;
  }

  .slideCount {
    flex: 0 0 auto;
  }


  .imageContainer img {
    border-radius: 10px;
    height: 550px;
    object-fit: cover;
    overflow: hidden;
    width: 100%;
  }
}

.title {
  width: 100%;

  h2 {
    color: var(--theme-color-1);
    font-size: 2.5rem;
    overflow: hidden;
    text-overflow: ellipsis;

    @include media-breakpoint-down(xs) {
      font-size: 2.125rem;
    }
  }

  h3 {
    color: var(--theme-color-1);
    font-family: $font-family-sans-serif;
    font-size: $h2-font-size;
    font-size: 2.5rem;
    font-weight: 200;
    overflow: hidden;
    text-overflow: ellipsis;

    @include media-breakpoint-down(xs) {
      font-size: 2.125rem;
    }
  }
}

.text {
  color: $gray-500;
  font-size: 1rem;
  line-height: 1.625;
}
