@import "variables";
@import "bootstrap/scss/mixins";

.CallToAction {
  padding: 1rem;

  @include media-breakpoint-up(lg) {
    padding: 6.25rem 6rem;
  }

  &:global(.bg-theme-1),
  &:global(.bg-theme-2),
  &:global(.bg-theme-3),
  &:global(.bg-theme-linear-gradient) {
    .title,
    .content {
      color: $white;
    }

    .subtitle {
      color: var(--theme-color-4);
    }
  }

  &,
  &:global(.bg-theme),
  &:global(.bg-theme-4) {
    .title {
      color: var(--theme-color-1);
    }

    .subtitle {
      color: var(--theme-color-1);
    }

    .content {
      color: $gray-500;
    }
  }

  .title {
    font-family: $font-family-sans-serif;
    font-size: 2.375rem;
  }

  .image {
    border-radius: 10px;
    height: auto;
    max-width: 500px;
    width: 100%;
  }

  .subtitle {
    font-family: Roboto, sans-serif;
    font-size: 1.25rem;
  }

  .content {
    font-family: Roboto, sans-serif;
    font-size: 1rem;
    letter-spacing: 0;
    line-height: 1.625rem;
    margin-top: 1.75rem;
  }

  .button {
    background-color: var(--tab-active-color);
    border: 0;
    border-radius: 2px;
    color: var(--text-white);
    display: inline-block;
    font-family: Roboto, sans-serif;
    font-size: 0.875rem;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 1.25rem;
    margin-top: 1.625rem;
    padding: 1rem;
  }
}
