@import "variables";
@import "bootstrap/scss/mixins";

.modal {
  background-color: $white;
  display: flex;
  flex-direction: row;
  left: 0;
  overflow: hidden;
  padding-right: 17px;
  position: fixed;
  top: 0;
  transform: translateZ(0);
  transition: transform 0.3s ease-in-out;
  width: 100vw;
  z-index: 1000;
}

.modalFullHeight {
  height: 100vh;
}

.fullPageScrollpane {
  background-color: $white;
  overflow-x: hidden;
  width: 100%;
}

.fullPageScrollpaneContent {
  background-color: $white;
  padding: 0 1rem;
  position: relative;
  width: 100%;
}

.fullHeight {
  height: 100%;
}

.fullPageHeader {
  align-items: center;
  background-color: $white;
  border-bottom: 1px solid $gray-200;
  display: flex;
  height: 50px;
  justify-content: space-between;
  padding: 1rem;
  position: relative;
  width: 100%;

  :global(.h1) {
    font-size: 1rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.fullPageFooter {
  background-color: $white;
  padding: 0 1rem;
  width: 100%;
  z-index: 1;
}

.hasABorder {
  border-top: 1px solid $gray-200;
}

.scrollbarFix {
  @include media-breakpoint-up(md) {
    margin-left: calc(100vw - 100%);
    margin-right: 17px;
  }
}

.noScroll {
  overflow: hidden !important;
}

.overlay {
  align-items: center;
  background-color: rgba(0, 0, 0, 25%);
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 900010;

  @include media-breakpoint-up(md) {
    display: flex;
    padding-right: 17px;
  }
}
