@import "variables";

.Breadcrumb {
  ol {
    display: flex;
    -ms-overflow-style: none;
    overflow-x: scroll;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  .breadcrumbItem {
    color: $gray-500;
    font-size: 0.75rem;
    white-space: nowrap;

    &::after {
      content: "|";
      margin-left: 0.25rem;
      margin-right: 0.25rem;
    }
  }

  .breadcrumbLastItem {
    font-weight: bold;

    &::after {
      content: "";
    }
  }

  .breadcrumbItemLink {
    color: inherit;
    font-size: 0.75rem;
  }
}
